<template>
  <div class="app">
    <el-card class="box-card">
      <!--列表-->
      <div v-if="!addStatus">
        <div class="listsHeader">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-popover
                placement="top"
                width="160"
                v-model="delAllStatus"
                style="margin-right: 8px;"
              >
                <p>您確認要放入時間機器嗎？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="delAllStatus = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="doRecycleAll">确定</el-button>
                </div>
                <el-button size="small" type="warning" slot="reference">
                  <i class="fa fa-recycle"></i>批量回收
                </el-button>
              </el-popover>

              <el-button
                size="small"
                type="info"
                @click="$refs.RoomTable.clearSelection()"
                style="margin-right: 8px;"
              >取消選擇</el-button>

              <el-button
                size="small"
                type="success"
                @click="addToEdit()"
                style="margin-right: 8px;"
              ><i class="el-icon-plus" style="font-weight: 900;"></i>  添加管理人員</el-button>
            </el-col>

            <el-col :span="12">
              <el-input placeholder="填寫查詢內容" size="small" v-model="query" class="employees-input">
                <el-select v-model="type" slot="prepend" placeholder="請選擇">
                  <el-option label="所有" value="0"></el-option>
                  <el-option label="賬戶號碼" value="1"></el-option>
                  <el-option label="手提號碼" value="2" style="margin-bottom: 20px;"></el-option>
                </el-select>
                <el-button slot="append" @click="queryBin">
                  <i class="fa fa-search"></i>
                </el-button>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <el-table
          class="lists"
          ref="RoomTable"
          :data="lists.list"
          :height="lists.total?clientHeight:'100'"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%;"
        >
          <el-table-column fixed type="selection" width="40"></el-table-column>
          <el-table-column fixed label="頭像" width="90">
            <template slot-scope="scope">
              <img
                class="table-user-head"
                :src="scope.row.avatarUrl?scope.row.avatarUrl:userHeadImg"
              />
            </template>
          </el-table-column>
          <el-table-column fixed prop="account" min-width="120" label="賬戶號碼"></el-table-column>
          <el-table-column prop="name" min-width="120" label="用戶名稱"></el-table-column>
          <el-table-column prop="phone" label="手提號碼" width="120"></el-table-column>
          <el-table-column label="性別" width="80">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-tag :type="sexColor[scope.row.sex]">{{sexType[scope.row.sex]}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="groupId" label="權限組" width="120">
            <template slot-scope="scope">
              <div style="text-align: center;">
                {{getGroupName(scope.row.groupId)}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="鎖定狀態" width="80">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-switch
                  v-model="scope.row.isLock"
                  active-color="#ff4949"
                  inactive-color="#eee"
                  @change="doLocked(scope.row.id, scope.row.isLock)"
                ></el-switch>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="登入次數" width="100">
            <template slot-scope="scope">
              <div style="text-align: center;">{{scope.row.logEntry}} 次</div>
            </template>
          </el-table-column>
          <el-table-column prop="logIp" label="登入IP" width="120"></el-table-column>
          <el-table-column prop="logTime" label="最新登入時間" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-dropdown
                  split-button
                  type="primary"
                  size="small"
                  @command="actionBtn"
                  v-if="scope.row.id !=1"
                >
                  <span @click="addToEdit(scope.row.id)">
                    <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 編輯
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{id:scope.row.id,name:'recycle'}">
                      <i class="fa fa-recycle"></i>回收
                    </el-dropdown-item>
                    <el-dropdown-item
                      style="color: #F56C6C;"
                      :command="{id:scope.row.id,name:'trash'}"
                      divided
                    >
                      <i class="fa fa-trash-alt"></i>刪除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div v-else>
                  <el-button
                    type="primary"
                    size="small"
                    v-if="$store.state.userInfo.adminId == 1"
                    @click="addToEdit(scope.row.id)"
                  >
                    <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 编辑
                  </el-button>
                  <el-button
                    size="small"
                    type="info"
                    @click="$refs.RoomTable.clearSelection()"
                    style="margin-right: 8px;"
                    v-else
                  >禁止操作</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="pager">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="lists.pageSize"
            :total="lists.total"
          ></el-pagination>
        </div>
      </div>

      <!--添加/编辑-->
      <div v-else>

        <div class="listsHeader">
          <el-row :gutter="24">
            <el-col :span="11">
              <el-button size="mini" type="warning" @click="addToEdit">
                <i class="fa fa-reply"></i>返回
              </el-button>
            </el-col>
            <el-col :span="13">{{editStatus?'編輯用戶':'添加用戶'}}</el-col>
          </el-row>
        </div>

        <el-row :gutter="24">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="member-ruleForm"
          >
            <el-col :span="24" style="text-align: center;margin-bottom: 20px;">
              <el-upload
                class="avatar-uploader"
                action="/v1/admin/avatarUploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :headers="uploaderHeaders"
              >
                <img
                  class="avatar"
                  :src="ruleForm.avatarUrl?ruleForm.avatarUrl:userHeadImg"
                />
              </el-upload>
            </el-col>

            <el-col :span="12">
              <el-form-item label="賬戶" prop="account">
                <el-input placeholder="請填寫賬戶號碼" v-model="ruleForm.account" :disabled="editStatus"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名稱" prop="name">
                <el-input placeholder="請填寫用戶名稱" v-model="ruleForm.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手提" prop="phone">
                <el-input placeholder="請填寫手提號碼" v-model="ruleForm.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用戶組" prop="groupId">
                <el-select v-model="ruleForm.groupId" placeholder="請選擇">
                  <el-option
                    v-for="item in groupList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="性別" prop="sex">
                <el-select v-model="ruleForm.sex" placeholder="請選擇性别" style="width: 100%;">
                  <el-option label="保密" value="0"></el-option>
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="鎖定狀態" prop="isLock">
                <el-select v-model="ruleForm.isLock" placeholder="請選擇" style="width: 100%;">
                  <el-option label="未鎖定" value="0"></el-option>
                  <el-option label="鎖定" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="密碼" :prop="editStatus?'':'password'">
                <el-input placeholder="請填寫密碼" v-model="ruleForm.password">
                  <template slot="append" v-if="editStatus">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="不修改密碼，請保持留空。"
                      placement="top-start"
                    >
                      <i class="fa fa-question"></i>
                    </el-tooltip>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="確認密碼" :prop="editStatus?'':'confirmPassword'">
                <el-input placeholder="請填寫確認密碼" v-model="ruleForm.confirmPassword"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div
                style="color: #C03639;font-size: 13px;text-align:left;float: left;"
                v-if="editStatus"
              ></div>
              <div style="text-align: right;">
                <el-button @click="resetForm('ruleForm')">重置</el-button>
                <el-button
                  type="primary"
                  @click="submitForm('ruleForm')"
                >{{editStatus?'立即編輯':'立即添加'}}</el-button>
              </div>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import defaultUserHead from '@/assets/img/admin/user/1.jpg'
export default {
  props: ["uploadText"],
  data() {
    return {
      uploaderHeaders:{Authorization: ''},
      clientHeight: 800,
      userHeadImg:defaultUserHead,
      type: "0",
      query: "",
      sexType: ["保密", "男", "女"],
      sexColor: ["info", "", "danger"],
      lists: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      groupList: [],
      multipleSelection: [],
      page: 1,
      delAllStatus: false,
      delAllStatus2: false,
      loading: false,

      // 添加/编辑
      addStatus: false,
      editStatus: false,
      rules: {
        account: [
          { required: true, message: "请输入账户", trigger: "blur" },
          {
            min: 3,
            max: 16,
            message: "长度在 3 到 16 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          { min: 8, max: 8, message: "长度在 8 位字符", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        groupId: [{ required: true, message: "请选择用户组", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
      },
      ruleForm: {
        id: 0,
        sex: "0",
        isLock: "0",
        account: "",
        name: "",
        phone: "",
        avatarUrl: "",
        groupId: "",
        password: "",
        confirmPassword: "",
      },
      // 测试分片上传
      uploading: false,
      loadingText: "上传进度",
      fileList: [],
    };
  },
  mounted() {
    let that = this;
    that.getList();
    that.getGroupListAll()
    let userInfo = this.$store.state.userInfo
    this.uploaderHeaders = {
      Authorization: 'Bearer ' + userInfo.token
    }
  },
  watch: {
    fileList(fileList) {
      this.$nextTick(() => {
        this.dealUpload();
      });
    },
  },
  methods: {
    getGroupName(id){
        let list = this.groupList
        for(let o in list){
          if(id == list[o].id){
            return list[o].title
          }
        }
      return ''
    },
    // 获取权限规则列表
    getGroupListAll(){
      let that = this;
      this.$http.GET(
        "/v1/auth/getGroupListAll",
        function (ret) {
          if (ret.errcode == 0) {
            that.groupList = ret.data.list || [];
          }
        }
      );
    },
    /**/
    getUserHeadImg (e) {
      e.src = userHeadImg
    },
    // 上传前
    beforeExcelUpload() {
      console.log("this.fileList", this.fileList);
    },
    // 上传请求
    handleUploadRequest(back) {
      this.fileList.push(back.file);
    },
    // 处理上传文件
    dealUpload() {
      this.uploading = true;
      this.$http.UPLOAD({
        files: this.fileList,
        pieceSize: 5,
        chunkUrl: "/v1/admin/avatarUploader",
        fileUrl: "/v1/admin/avatarUploader2",
        progress: (num) => {
          this.loadingText = "上传进度" + num + "%";
        },
        success: (data) => {
          this.uploading = false;
          console.log("success", data);
          //this.$emit('uploaded', data)
        },
        error: (e) => {
          this.uploading = false;
          console.log("error", e);
        },
      });
    },
    // 添加/编辑用户
    addToEdit(id) {
      let that = this;
      if (id >= 1) {
        that.getAdminInfo(id);
        that.addStatus = !that.addStatus;
        that.editStatus = true;
      } else {
        that.ruleForm = {
          id: 0,
          sex: "0",
          isLock: "0",
          account: "",
          name: "",
          phone: "",
          avatarUrl: "",
          groupId: "",
          password: "",
          confirmPassword: "",
        };
        that.addStatus = !that.addStatus;
        that.editStatus = false;
      }
    },
    // 获取用户信息
    getAdminInfo(id) {
      let that = this;
      let param = that.$atk.json_to_url({
        id: id,
      });
      this.$http.GET(
        "/v1/admin/getMemberInfo?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            let data = ret.data || [];
            data.sex = data.sex.toString();
            data.isLock = data.isLock.toString();
            that.ruleForm = data;
          } else if (ret.errcode == 1) {
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 头像上传成功
    handleAvatarSuccess(res, file) {
      if (res.errcode == 0) {
        this.$notify({
          title: "提示",
          message: "上传成功",
          type: "success",
        });
        this.ruleForm.avatarUrl = res.url;
      } else {
        this.$notify({
          title: "提示",
          message: res.errmsg,
          type: "warning",
        });
      }
    },
    // 上传验证
    beforeAvatarUpload(file) {
      const size = file.size / 1024 / 1024 < 200;
      if (!size) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return size;
    },
    // 提交添加/编辑
    doAddToEdit() {
      let that = this;
      let pDate = {
        ...that.ruleForm,
        sex: parseInt(that.ruleForm.sex),
        isLock: parseInt(that.ruleForm.isLock),
        groupId: parseInt(that.ruleForm.groupId),
      }
      if (pDate.password || pDate.confirmPassword) {
        if (!pDate.confirmPassword) {
          that.$message({
            message: "确认密码不能为空",
            type: "warning",
          });
          return false;
        } else if (pDate.confirmPassword != pDate.password) {
          that.$message({
            message: "密码不一致",
            type: "warning",
          });
          return false;
        }
      }
      if (pDate.id <= 0) {
        //that.$delete(that.ruleForm,'id')
        that.$http.POST("/v1/admin/doAdd", pDate, function (ret) {
            if (ret.errcode == 0) {
              that.$notify({
                title: "成功",
                message: ret.errmsg,
                type: "success",
              });
              that.getList();
              that.addStatus = !that.addStatus;
              that.editStatus = !that.editStatus;
            } else if (ret.errcode >= 1) {
              that.$notify.error({
                title: "错误",
                message: ret.errmsg,
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      } else {
        that.$http.PUT("/v1/admin/doEdit", pDate, function (ret) {
            if (ret.errcode == 0) {
              that.$notify({
                title: "成功",
                message: ret.errmsg,
                type: "success",
              });
              let userInfo = that.$atk.Store.store.get("userInfo");
              if (pDate.avatarUrl && userInfo.adminId == pDate.id) {
                userInfo.avatarUrl = pDate.avatarUrl;
                userInfo.name = pDate.name;
                userInfo.sex = parseInt(pDate.sex);
                userInfo.groupId = parseInt(pDate.groupId);
                that.$atk.Store.store.set("userInfo", userInfo);
                that.$store.state.userInfo = userInfo;
              }
              that.getList();
              that.addStatus = !that.addStatus;
              that.editStatus = !that.editStatus;
            } else if (ret.errcode == 1) {
              that.$notify.error({
                title: "错误",
                message: ret.errmsg,
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      }
    },
    // 提交
    submitForm(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.doAddToEdit();
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 操作按钮
    actionBtn(command) {
      let that = this;
      if (command.name == "recycle") {
        that.$confirm("您确认要回收此账户吗?", "回收提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.doRecycle([command.id]);
          })
          .catch(() => {
            that.$notify({
              title: "回收提示",
              message: "已取消回收用户",
              type: "warning",
            });
          });
      } else if (command.name == "trash") {
        that
          .$confirm("您确认要删除此账户吗?", "删除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.doDelete(command.id);
          })
          .catch(() => {
            that.$notify({
              title: "删除提示",
              message: "已取消删除用户",
              type: "warning",
            });
          });
      }
    },
    // 获取账户列表
    getList(page = 0) {
      let that = this;
      let param = that.$atk.json_to_url({
        page: page || parseInt(that.page),
        type: parseInt(that.type),
        query: that.query,
      });
      that.loading = true;
      this.$http.GET(
        "/v1/admin/getMemberList?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            let data = ret.data || [];
            for (let i in data.list) {
              data.list[i].sex = data.list[i].sex.toString();
               data.list[i].isLock = data.list[i].isLock?true:false;
            }
            that.lists = data;
            that.loading = false;
          } else if (ret.errcode == 1) {
            that.loading = false;
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.loading = false;
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getList(val);
    },
    // 设置管理人员锁定状态
    doLocked (id, isLock) {
      let that = this;
      let s = isLock =! isLock

      let param = {
        id: id,
        isLock: s?0:1
      };
      that.$http.PUT(
        "/v1/admin/locked", param,function (ret) {
          if (ret.errcode == 0) {
            that.$notify({
              title: "成功",
              message: ret.errmsg,
              type: "success",
            });
          } else if (ret.errcode == 1) {
            that.$notify.error({
              title: "错误",
              message: ret.errmsg,
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 回收用户
    doRecycle(arrayId) {
      let that = this;
      let param = that.$atk.json_to_url({
        ids: arrayId,
      });
      that.$http.DELETE(
        "/v1/admin/recycleMember?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            that.$notify({
              title: "成功",
              message: ret.errmsg,
              type: "success",
            });
            for (let key in arrayId) {
              that.deleteItem(arrayId[key], that.lists.list);
            }
          } else if (ret.errcode == 1) {
            that.$notify.error({
              title: "错误",
              message: ret.errmsg,
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 删除用户
    doDelete(id) {
      let that = this;
      let param = that.$atk.json_to_url({
        id: id,
      });
      that.$http.DELETE(
        "/v1/admin/doDelete?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            that.$notify({
              title: "成功",
              message: ret.errmsg,
              type: "success",
            });
            that.deleteItem(id, that.lists.list);
          } else if (ret.errcode == 1) {
            that.$notify.error({
              title: "错误",
              message: ret.errmsg,
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 删除列表用户
    deleteItem(item, list) {
      for (let key in this.lists.list) {
        if (list[key].id === item) {
          list.splice(key, 1);
        }
      }
    },
    // 选择用户
    handleSelectionChange(val) {
      this.multipleSelection = val; //.filter(val => val.id!=1)
      let row = val.filter((val) => val.id == 1);
      this.$refs.RoomTable.toggleRowSelection(row[0], false);
    },
    // 回收已选择用户
    doRecycleAll() {
      let that = this;
      let length = that.multipleSelection.length;
      if (length <= 0) {
        that.$notify({
          title: "回收提示",
          message: "请勾选回收用户",
          type: "warning",
        });
        that.delAllStatus = false;
        return;
      }
      let idList = [];
      for (let i in that.multipleSelection) {
        idList[i] = that.multipleSelection[i].id;
      }
      that.doRecycle(idList);
      that.delAllStatus = false;
      //that.$notify({
      //    title: '回收提示',
      //    message: '回收用户成功',
      //    type: 'success'
      //});
    },
    // 查询
    queryBin() {
      let that = this;
      if (!that.query) {
        that.$message({
          message: "请输入查询内容",
          type: "warning",
        });
        return;
      }
      this.getList();
    },
    // changeFixed(){
    //     let clientHeight = window.innerHeight - 260
    //     if(clientHeight > 300){
    //         this.clientHeight = clientHeight
    //     }
    // }
  },
};
</script>

<style scoped lang="scss">
.upload-block {
  text-align: center;
  line-height: 80px;
  height: 80px;
  .el-upload {
    width: 100%;
    height: 100%;
    .el-upload-dragger {
      width: 100%;
      height: 100%;
      .el-upload__text {
        position: relative;
        font-size: 1.2em;
        color: #409eff;
        i {
          font-size: 1.5em;
          margin-right: 10px;
        }
      }
    }
  }
}

.box-card {
  min-height: 10px;
  .listsHeader {
    min-height: 50px;
  }
  .pager {
    padding-top: 8px;
  }
}
.employees-input {
  button {
    height: 100%;
    padding: 9px 20px;
    border-radius: 0px 3px 3px 0px;
    color: #fff;
    background-color: #25334e;
  }
  button:hover {
    background-color: #182132;
  }
}
.table-user-head {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
/* 列表 */
.listsHeader{
  .el-input-group__prepend .el-select {
    width: 90px;
  }
}
/* 编辑或者添加 */
.member-ruleForm{
  .avatar-uploader {
    .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
      border-radius: 6px;
    }
  }
}
</style>
